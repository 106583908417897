import React, { createRef, useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { WidgetsContext } from '../Widgets'
import { FiHelpCircle } from 'react-icons/fi'
import { Modal } from './Builder.styles'
import useSwal from '@hooks/useSwal'
import AuthContext from '@contexts/Auth'
import $Widget from '@services/Widget'
import ReactTooltip from 'react-tooltip'

type Selection = {
  query: string | null
  roleId?: number | null
  checked: boolean
}

const roles = {
  2: 'Gestor',
  3: 'Professor',
  4: 'Revisor',
}

const Builder: React.FC = () => {
  const context = useContext(AuthContext)

  const { clients, builder, setBuilder, $widgets } = useContext(WidgetsContext)

  const [ selection, setSelection ] = useState<Record<number, Selection>>({})

  const swal = useSwal()

  const clientRef = createRef<HTMLSelectElement>()

  const $store = useMutation($Widget.clients.store)

  useEffect(() => {
    const element = clientRef.current

    if (!element || !builder?.client)
      return

    element.value = String(builder.client.id)
  }, [clientRef, builder?.client])

  useEffect(() => {
    const client = builder?.client

    if (!client)
      return

    setSelection(selection => {
      client.widgets.forEach(({ id, query, role }) => {
        selection[id] = {
          query,
          checked: true,
          roleId: role?.id,
        }
      })

      return {
        ...selection,
      }
    })
  }, [builder?.client])

  const onCheckChange = ({ target: { checked, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const id = Number(value)

    setSelection(selection => {
      selection[id] = {
        ...selection[id],
        checked,
      }

      return {
        ...selection,
      }
    })
  }

  const onRoleChange = (id: number, value: string | null) => {
    setSelection(selection => {
      selection[id] = {
        ...selection[id],
        roleId: value && value.length > 0 ? Number(value) : null,
      }

      return {
        ...selection,
      }
    })
  }

  const onQueryChange = (id: number, query: string) => {
    setSelection(selection => {
      selection[id] = {
        ...selection[id],
        query,
      }

      return {
        ...selection,
      }
    })
  }

  const onHide = () => {
    setSelection({})
    setBuilder(null)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!selection || !clientRef.current)
      return

    const clientId = builder?.client?.id || Number(clientRef.current.value)

    const widgets = Object.entries(selection).reduce((widgets, [id, { checked, query, roleId }]) => {
      if (checked) {
        widgets.push({
          id: Number(id),
          query: query && query.length > 0 ? query.trim() : null,
          roleId,
        })
      }

      return widgets
    }, [] as Array<Omit<Selection, 'checked'> & { id: number }>)

    if (!widgets.length) {
      swal.fire({
        icon: 'error',
        title: 'Selecione pelo menos um widget',
      })

      return
    }

    $store.mutateAsync({
      clientId,
      widgets,
    }).then(() => {
      $widgets.refetch().finally(onHide)
    })
  }

  const isLoading = $store.isLoading || $widgets.isLoading

  return (
    <Modal
      size="lg"
      show={!!builder}
      onHide={onHide}
      centered
    >
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">Adicionar cliente</Modal.Title>
        </Modal.Header>

        <Modal.Body className="pb-0">
          <div className="form-group mb-3">
            <label htmlFor="clientId">*Cliente:</label>

            <select ref={clientRef} name="clientId" className="form-control" disabled={$store.isLoading || $widgets.isLoading || !!builder?.client} required>
              {context.clients.map(({ id, name }) => (
                <option key={id} value={id} disabled={clients.some(client => client.id === id)}>
                  {name}
                </option>
              ))}
            </select>
          </div>

          <div className="widgets mb-3">
            <table className="table table-default table-bordered table-sm">
              <thead>
                <tr>
                  <th style={{ width: 40 }}></th>
                  <th>Widget</th>
                  <th>Cargo</th>
                  <th>Query <FiHelpCircle data-tip data-for="helpCircle" /></th>
                </tr>
              </thead>

              <tbody>
                {$widgets.data?.map(({ id, name }) => (
                  <tr key={id}>
                    <td>
                      <input type="checkbox" className="form-check-input" value={id} checked={selection[id]?.checked ?? false} onChange={onCheckChange} disabled={isLoading} />
                    </td>

                    <td>{name}</td>

                    <td>
                      <select className="form-select" value={`${selection[id]?.roleId}`} onChange={({ target: { value } }) => onRoleChange(id, value)} disabled={!selection[id]?.checked || isLoading}>
                        <option value="">Todas os cargos</option>
                        {Object.entries(roles).map(([id, name]) => (
                          <option key={id} value={id}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </td>

                    <td>
                      <input type="text" className="form-control" value={`${selection[id]?.query ?? ''}`} onChange={({ target: { value } }) => onQueryChange(id, value)} disabled={!selection[id]?.checked || isLoading} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <ReactTooltip id="helpCircle" place="top" effect="solid">
              O preenchimento deste campo sobrescreverá a query padrão do widget.
            </ReactTooltip>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button type="submit" className="btn btn-primary" disabled={isLoading || !Object.values(selection).some(value => value.checked)}>
            {builder?.client ? 'Atualizar' : 'Salvar'} {(isLoading) && <Spinner animation="border" size="sm" />}
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default Builder
