import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import IApp from '@interfaces/IApp'

const all: QueryFunction<IApp[], string> = () => {
  return axios.get('/apps').then<IApp[]>(({ data }) => data)
}

export const create = (app: any): Promise<any> => {
  return axios.post('/apps', app)
}

export const update = (app: any): Promise<any> => {
  return axios.put(`/apps/${app.id}`, app)
}

export const destroy = (id: number): Promise<any> => {
  return axios.delete(`/apps/${id}`)
}

const dashboard = {
  login: (() => axios.post('/apps/dashboard/login')) as MutationFunction<AxiosResponse<{ token: string }>, null>,
}

const $App = {
  all,
  create,
  update,
  destroy,
  dashboard,
}

export default $App
