import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import IClient from '@interfaces/IClient'
import * as $Client from '@services/Client'
import useTable from '@hooks/useTable'
import useClientColumns from '@hooks/useClientColumns'

const Clients: React.FC<any> = () => {
  const [ search, setSearch ] = useState<string | null>(null)
  const [ active, setActive ] = useState<boolean | null>(null)
  const [ canEdit, setCanEdit ] = useState<boolean | null>(null)

  const $clients = useQuery({
    queryFn: () => $Client.all().then(({ data }) => data),
    queryKey: [
      'clients',
    ],
  })

  const columns = useClientColumns()

  const data = useMemo(() => {
    const clients = $clients.data

    if (!clients)
      return []

    return clients.filter((client: IClient) => {
      let exists = true

      if (search && search.length > 0)
        exists = client.id.toString().includes(search) || client.name.toLowerCase().includes(search.toLowerCase())

      if (exists && active !== null)
        exists = client.active == active

      if (exists && canEdit !== null)
        exists = client.canEdit == canEdit

      return exists
    })
  }, [$clients.data, search, active, canEdit])

  const { Table, Pagination } = useTable(data, {
    columns,
    isLoading: $clients.isLoading,
  })

  const onActiveChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setActive(e.target.value === '' ? null : e.target.value === '1')
  }

  const onCanEditChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCanEdit(e.target.value === '' ? null : e.target.value === '1')
  }

  return (
    <>
      <div className="row align-items-end mb-3">
        <div className="col-12 col-md-7">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="search">Pesquisar:</label>
                <input type="text" id="search" name="search" className="form-control" placeholder="Pesquise pelo ID ou nome" onChange={e => setSearch(e.target.value)} />
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="active">Ativo:</label>
                <select className="form-control" onChange={onActiveChange}>
                  <option value="">Todos</option>
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group">
                <label htmlFor="canEdit">Editável:</label>
                <select className="form-control" onChange={onCanEditChange}>
                  <option value="">Todos</option>
                  <option value="0">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">Clientes</div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <Table />
          </div>
        </div>

        <Pagination>
          {pagination => (
            <div className="card-footer d-flex justify-content-end">
              {pagination}
            </div>
          )}
        </Pagination>
      </div>
    </>
  )
}

export default Clients
