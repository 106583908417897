import axios, { AxiosResponse } from 'axios'
import { MutationFunction, QueryFunction } from 'react-query'
import IWidget from '@interfaces/IWidget'

const all: QueryFunction<IWidget[], string> = () => {
  return axios.get('/widgets').then<IWidget[]>(({ data }) => data)
}

const find: QueryFunction<IWidget, [string, number]> = ({ queryKey: [, id] }) => {
  return axios.get(`/widgets/${id}`).then<IWidget>(({ data }) => data)
}

const update: MutationFunction<AxiosResponse, [number, Partial<IWidget & { platformId: number | null }>]> = ([id, data]) => {
  return axios.put(`/widgets/${id}`, data)
}

const clients = {
  store: ((data) => {
    return axios.post('/widgets/clients', data)
  }) as MutationFunction<AxiosResponse, { clientId: number, widgets: { id: number, query: string | null }[] }>,

  delete: (id => {
    return axios.delete(`/widgets/clients/${id}`)
  }) as MutationFunction<AxiosResponse, number>,
}

const $Widget = {
  all,
  find,
  update,
  clients,
}

export default $Widget
