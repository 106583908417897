import React, { useCallback, useContext } from 'react'
import { useMutation, UseQueryResult } from 'react-query'
import { WidgetsContext } from '../Widgets'
import useWidgetClientColumns from '@hooks/useWidgetClientColumns'
import useTable from '@hooks/useTable'
import IWidget from '@interfaces/IWidget'
import $Widget from '@services/Widget'

type Props = {
  $widgets: UseQueryResult<IWidget[], unknown>
}

const Clients: React.FC<Props> = () => {
  const { clients, $widgets } = useContext(WidgetsContext)

  const $delete = useMutation($Widget.clients.delete)

  const onDelete = useCallback((id: number) => {
    $delete.mutateAsync(id).finally(() => {
      $widgets.refetch()
    })
  }, [$delete, $widgets])

  const columns = useWidgetClientColumns(onDelete)

  const { Table, Pagination } = useTable(clients, {
    columns,
    isLoading: $widgets.isLoading,
  })

  return (
    <>
      <div className="card">
        <div className="card-header mb-3">
          <div className="card-title mb-0">Clientes</div>
        </div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <Table />
          </div>
        </div>

        <Pagination>
          {pagination => (
            <div className="card-footer d-flex justify-content-end">
              {pagination}
            </div>
          )}
        </Pagination>
      </div>
    </>
  )
}

export default Clients
