import axios from 'axios'

export const all = (schoolId: number, year: number): Promise<any> => {
  return axios.get(`/schools/${schoolId}/degrees`, {
    params: {
      year,
    },
  })
}

export const allAdmin = (schoolId: number): Promise<any> => {
  return axios.get(`/schools/${schoolId}/degrees`)
}
