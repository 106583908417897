import IUser from '@interfaces/IUser'
import IUserGroup from '@interfaces/IUserGroup'
import IUserSchool from '@interfaces/IUserSchool'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

export const all = (formData: FormData, config?: AxiosRequestConfig): Promise<any> => axios.get('users', {
  ...config,
  params: {
    search: formData.get('search'),
    id: formData.get('id'),
    name: formData.get('name'),
    email: formData.get('email'),
    userCode: formData.get('userCode'),
    clientId: formData.get('clientId'),
    schoolId: formData.get('schoolId'),
  },
})

export const find = (userId: number): Promise<any> => axios.get(`users/${userId}`)

export const update = (userId: number, formData: FormData): Promise<any> => {
  return axios.put(`users/${userId}`, formData)
}

export const sendPassword = (userId: number) : Promise<any> => {
  return axios.post(`users/${userId}/send-password`)
}

export const userGroup = (userId: number) : Promise<AxiosResponse<IUserGroup>> => {
  return axios.get(`users/${userId}/user-group`)
}

export const destroy = (userId: number, schoolId: number): Promise<any> => {
  return axios.delete(`users/${userId}/${schoolId}`)
}

export const setDefaultSchool = (userId: number, schoolId: number): Promise<any> => axios.post(`users/${userId}/set-default-school/${schoolId}`)

export const schools = (userId: number, userSchoolId: number, schoolId: number): Promise<any> => axios.get(`users/${userId}/${userSchoolId}/school/${schoolId}`)

export const setAccessUser = (userSchoolId: number, schoolId: number, adminId: number, user: IUser): Promise<any> => {
  return axios.post(`users/${userSchoolId}/set-access-user/${schoolId}/${adminId}`, user)
}

export const findUserSchool = (userSchoolId: number): Promise<any> => axios.get(`users/school/${userSchoolId}`)

export const updateUserSchool = (adminId: number, user: IUserSchool): Promise<any> => {
  return axios.put(`users/school/update/${adminId}`, user)
}

const $User = {
  all,
  userGroup,
}

export default $User
