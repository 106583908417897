import styled from 'styled-components'

export const Thumbnail = styled.label`
  > .row {
    > .col {
      > label {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: #eee;
        aspect-ratio: 1/.56;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        padding: 0.5rem;

        > span {
          width: 100%;
          height: 100%;
          display: block;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
        }

        > input {
          opacity: 0;
          position: absolute;
          left: 50%;
        }
      }
    }
  }
`
