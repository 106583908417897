import React, { useContext, useMemo } from 'react'
import { useMutation } from 'react-query'
import { Dropdown } from 'react-bootstrap'
import IUser from '@interfaces/IUser'
import axios from 'axios'
import AuthContext from '@contexts/Auth'
import UserOrigin from '@enums/UserOrigin.enum'

const Platforms: React.FC<{ user: IUser }> = ({ user }) => {
  const { $apps } = useContext(AuthContext)

  const $redirect = useMutation({
    mutationFn: ({ appKey, domain }: { appKey: string, domain: string }) => {
      return axios.post('/auth/login', {
        appKey: appKey,
        userId: user.id,
        origin: UserOrigin.Centralizador,
      }, {
        baseURL: `https://${domain}`,
      })
    },
    onSuccess: ({ data: { redirectUrl } }) => {
      window.open(redirectUrl, '_blank', 'noreferrer')
    },
  })

  const options = useMemo(() => {
    const options: Record<number, { id: number, name: string, domain: string, appKey: string }> = {}

    $apps.data?.forEach(app => app.appDomains.forEach(appDomain => {
      if (appDomain.client && appDomain.client.id === user.clientId) {
        options[appDomain.id] = {
          id: appDomain.id,
          name: app.name,
          domain: app.api.domain,
          appKey: appDomain.appKey,
        }
      }
    }))

    return Object.values(options)
  }, [$apps.data, user.clientId])

  if (!options.length)
    return null

  return (
    <Dropdown>
      <Dropdown.Toggle variant="primary" className="pe-3 ps-3">
        Simular
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map(({ id, name, domain, appKey }) => (
          <Dropdown.Item
            key={`${user.id}-${id}`}
            onClick={() => $redirect.mutate({
              appKey,
              domain,
            })}
          >
            {name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default Platforms
