import styled from 'styled-components'
import { Modal as ModalComponent } from 'react-bootstrap'

export const Modal = styled(ModalComponent)`
  > .modal-dialog {
    max-width: 1760px;
    height: calc(100% - var(--bs-modal-margin) * 2);

    > .modal-content {
      height: 100%;

      > .modal-body {
        height: 100%;

        > .dashboard-iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }
      }
    }
  }
`
