import { MutationFunction, QueryFunction } from 'react-query'
import axios, { AxiosResponse } from 'axios'
import IWidget from '@interfaces/IWidget'
import IClient from '@interfaces/IClient'

export const all = () : Promise<AxiosResponse<IClient[]>> => {
  return axios.get('/clients/')
}

export const find: QueryFunction<IClient, [string, number]> = ({ queryKey: [, id] }) => {
  return axios.get(`clients/${id}`).then<IClient>(({ data }) => data)
}

export const update: MutationFunction<AxiosResponse, { id: number, formData: FormData }> = ({ id, formData }) => {
  return axios.put('clients/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const widgets: QueryFunction<Array<IWidget>, [string, number]> = ({ queryKey: [, id] }) => {
  return axios.get(`/widgets/clients/${id}`).then(({ data }) => data)
}

const $Client = {
  all,
  find,
  update,
  widgets,
}

export default $Client
