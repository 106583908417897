import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import IUserGroup from '@interfaces/IUserGroup'
import $UserGroup from '@services/UserGroup'
import AuthContext from '@contexts/Auth'
import Select from '@components/Select/Select'
import Table from '@components/Table/Table'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import useUserGroupColumns from '@hooks/useUserGroupColumns'
import IUser from '@interfaces/IUser'

const UserGroups: React.FC = () => {
  const { clients } = useContext(AuthContext)

  const [ userGroups, setUserGroups ] = useState<IUserGroup[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ clientId, setClientId ] = useState<number | null>(null)
  const [ school, setSchool ] = useState<string>('')
  const [ user, setUser ] = useState<string>('')

  const columns = useUserGroupColumns()

  useEffect(() => {
    $UserGroup.all().then(({ data: userGroups }) => setUserGroups(userGroups)).finally(() => setIsLoading(false))
  }, [])

  const userFilter = useCallback(({ id, name, email, userCode }: IUser) => {
    return (
      id === Number(user) ||
      name.toLowerCase().includes(user.toLowerCase()) ||
      email.toLowerCase().includes(user.toLowerCase()) ||
      userCode === Number(user)
    )
  }, [user])

  const data = useMemo(() => {
    return userGroups.filter(userGroup => {
      let exists = true

      if (clientId) {
        exists = userGroup.client.id === clientId
      }

      if (exists && school.length > 0) {
        exists = userGroup.users.some(({ school: s }) => (
          s?.id === Number(school) ||
          s?.name.toLowerCase().includes(school.toLowerCase())
        ))
      }

      if (exists && user.length > 0) {
        exists = userGroup.users.some(userFilter)
      }

      return exists
    }).map(userGroup => ({
      ...userGroup,
      users: userGroup.users.filter(userFilter),
    }))
  }, [clientId, school, user.length, userFilter, userGroups])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  } = useTable<IUserGroup>({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    },
  }, useSortBy, usePagination)

  return (
    <>
      <div className="row align-items-end">
        <div className="col-6 col-md-3 col-xl-2">
          <div className="form-group mb-3">
            <label>Cliente:</label>
            {clients && clients.length > 0 && (
              <Select
                onChange={(client: any) => setClientId(client?.value)}
                options={clients.map(client => ({
                  value: client.id,
                  label: client.name,
                }))}
                isSearchable
                isClearable
                placeholder="Selecione um cliente"
              />
            )}
          </div>
        </div>

        <div className="col-6 col-md-3">
          <div className="form-group mb-3">
            <label>Escola:</label>
            <input type="text" className="form-control" placeholder="ID da escola ou nome fantasia" onChange={({ target: { value } }) => setSchool(value)} />
          </div>
        </div>

        <div className="col-6 col-md-3">
          <div className="form-group mb-3">
            <label>Usuário:</label>
            <input type="text" className="form-control" placeholder="ID do usuário, RE, nome ou e-mail" onChange={({ target: { value } }) => setUser(value)} />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-header">Grupo de usuários</div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <Table
              getTableProps={getTableProps}
              getTableHeadProps={getTableHeadProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              isLoading={isLoading}
              page={page}
            />
          </div>
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default UserGroups
