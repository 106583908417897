import axios from 'axios'

export const all = (): Promise<any> => axios.get('platforms')

export const allFromSchoolId = (schoolId: number, params: any = {}): Promise<any> => {
  return axios.get('platforms/' + schoolId, {
    params,
  })
}

export const degreeGroups = (schoolId: number, degreeId: number, year?: number): Promise<any> => {
  return axios.get(`platforms/${schoolId}/degree-groups/${degreeId}`, {
    params: {
      year,
    },
  })
}

const $Platform = {
  all,
  allFromSchoolId,
  degreeGroups,
}

export default $Platform
