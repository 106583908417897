import React, { useMemo } from 'react'
import { Container } from './ImageUpload.styles'
import { IoCloseSharp } from 'react-icons/io5'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  name: string
  label: string
  cover?: string | null
  accept: string
  required?: boolean
  onFileChange?: (file: File) => void
}

const ImageUpload: React.FC<Props> = ({ name, label, cover, accept, required, onFileChange, ...props }: Props) => {
  const [ file, setFile ] = React.useState<File|null>(null)

  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0])
      if (onFileChange) {
        onFileChange(e.target.files[0])
      }
    }
  }

  const imageUrl = useMemo(() => file ? URL.createObjectURL(file) : cover ?? null, [cover, file])

  const onRemove = () => {
    setFile(null)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const onClick = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  return (
    <Container {...props}>
      <label
        style={{
          backgroundImage: imageUrl ? `url(${imageUrl})` : '',
        }}
      >
        <input ref={inputRef} type="file" name={name} id={name} accept={accept} required={required} onChange={handleFileChange} />
      </label>

      <div className="btn-group">
        <button type="button" className="btn btn-primary btn-sm pe-3 ps-3" onClick={onClick}>
          {file ? `Alterar ${label.toLowerCase()}` : `Selecionar ${label.toLowerCase()}`}
        </button>

        {file && (
          <button type="button" className="btn btn-light btn-sm pe-1 ps-1" onClick={onRemove}>
            <IoCloseSharp size={18} />
          </button>
        )}
      </div>
    </Container>
  )
}

export default ImageUpload
