import Pagination from '@components/Pagination/Pagination'
import Table from '@components/Table/Table'
import React from 'react'
import { Column, usePagination, useSortBy, useTable as useReactTable } from 'react-table'

type Props<T extends object = {}> = {
  columns: Column<T>[],
  isLoading?: boolean,
}

type ReturnType = {
  Table: React.FC,
  Pagination: React.FC<{ children: (pagination: React.ReactElement) => React.ReactElement }>
}

const useTable = <T extends object = {}>(data: Array<T> = [], { columns, isLoading }: Props<T>) : ReturnType => {
  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  } = useReactTable<T>({
    columns,
    data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    },
  }, useSortBy, usePagination)

  return {
    Table: () => (
      <Table
        getTableProps={getTableProps}
        getTableHeadProps={getTableHeadProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        isLoading={isLoading || false}
        page={page}
      />
    ),
    Pagination: ({ children }) => {
      return children && children(
        <Pagination
          page={pageIndex}
          pageCount={pageCount}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          onClick={(page: number) => gotoPage(page)}
        />
      )
    }
  }
}

export default useTable
