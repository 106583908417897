import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as $Class from '@services/Class'
import * as $School from '@services/School'
import * as $Degree from '@services/Degree'
import Pagination from '@components/Pagination/Pagination'
import { Spinner } from 'react-bootstrap'
import { usePagination, useSortBy, useTable } from 'react-table'
import columns from '@data/ReactTableClassesColumns'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import AuthContext from '@contexts/Auth'
import Select from '@components/Select/Select'
import ISchool from '@interfaces/ISchool'
import IClass from '@interfaces/IClass'
import IDegree from '@interfaces/IDegree'
import axios from 'axios'
import { now } from 'lodash'
import { Buttons } from './Classes.styles'
import { useNavigate, Link } from 'react-router-dom'

const Classes: React.FC<any> = () => {
  const { client } = useContext(AuthContext)

  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ schools, setSchools ] = useState<ISchool[]>([])
  const [ schoolId, setSchoolId ] = useState<number>(0)
  const [ classes, setClasses ] = useState<IClass[]>([])
  const [ degrees, setDegrees ] = useState<IDegree[]>([])
  const [ degreeId, setDegreeId ] = useState<string>('')
  const [ search, setSearch ] = useState<string>('')

  const navigate = useNavigate()

  useEffect(() => {
    if (client?.id) {
      setSchoolId(0)
      setIsLoading(true)

      $School.all().then(({ data }: any) => {
        setSchools(data)
      }).finally(() => setIsLoading(false))
    }
  }, [client])

  useEffect(() => {
    setDegrees([])
    if (schoolId > 0) {
      axios.all([
        $Class.fromSchoolId(schoolId),
        $Degree.all(schoolId, new Date(now()).getFullYear()),
      ]).then(axios.spread(({ data: classes }: any, { data: degrees }: any) => {
        setClasses(classes),
        setDegrees(degrees)
      })).finally(() => setIsLoading(false))
    }
  }, [schoolId])

  const data = useMemo(() => classes.filter((c: IClass) => {
    let exists = true

    if (search.length > 0)
      exists = c.id.toString().includes(search) ||
        c.name.toLowerCase().includes(search.toLowerCase())
    if (exists && degreeId.length > 0)
      exists = c.degreeId === parseInt(degreeId)

    return exists
  }), [classes, search, degreeId])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const handleSchoolIdChange = ({ value: schoolId }: any) => setSchoolId(schoolId)

  const options = useMemo(() => [
    ...schools.map((school: ISchool) => ({
      value: school.id,
      label: school.name,
    }))], [schools])

  return (
    <>
      <div className="row align-items-end">
        <div className="col-12 col-lg-10">
          <div className="row">
            {options.length > 0 && (
              <div className="col-12 col-md-4 form-group mb-3 schools">
                <label htmlFor="search">Escola:</label>
                <div className="select-schools">
                  <Select
                    type="primary"
                    onChange={handleSchoolIdChange}
                    defaultValue={0}
                    options={options}
                    isSearchable={true}
                    disabled={isLoading}
                    placeholder="Selecione uma escola"
                  />
                </div>
              </div>
            )}

            {classes.length > 0 && (
              <>
                <div className="col-6 col-md-3 form-group mb-3">
                  <label htmlFor="degreeId">Série:</label>
                  <select
                    id="degreeId"
                    name="degreeId"
                    className="form-control"
                    onChange={e => setDegreeId(e.target.value.length > 0 ? e.target.value : '')}
                  >
                    <option value="">Todas</option>
                    {degrees.map((degree: IDegree) => (
                      <option value={degree.id} key={degree.id}>{degree.name}</option>
                    ))}
                  </select>
                </div>

                <div className="col-6 col-md-3 form-group mb-3 userCode">
                  <label htmlFor="userCode">Pesquisar:</label>
                  <input
                    type="text"
                    id="search"
                    name="search"
                    className="form-control"
                    placeholder="Pesquise pelo ID ou nome"
                    onChange={e => setSearch(e.target.value)}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <Buttons className="col-12 col-lg-2 d-flex justify-content-end">
          <button className="btn btn-primary mb-3" onClick={() => navigate('/turmas/cadastrar')} disabled={isLoading}>Cadastrar</button>
        </Buttons>
      </div>

      <div className="card">
        <div className="card-header">Turmas</div>

        <div className="card-body p-0">
          {!isLoading ? (data.length ? (
            <div className="table-responsive">
              <table {...getTableProps()} className="table table-default mb-0">
                <thead>
                  {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                      {headerGroup.headers.map((column: any, columnIndex: number) => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                          {column.render('Header')}
                          <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                        </th>
                      ))}
                      <th style={{ width: 120 }}>Ações</th>
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                  {page.map((row: any, rowIndex: number) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={rowIndex}>
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                              <span className="text-truncate">{cell.render('Cell')}</span>
                            </td>
                          )
                        })}
                        <td style={{ verticalAlign: 'middle' }}>
                          <div className="d-flex justify-content-center">
                            <Link to={`/turmas/${row.values.id}/editar`} className="btn btn-sm ps-4 pe-4 btn-primary">Editar</Link>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : schoolId > 0 || classes.length > 0 ? (
            <div className="bg-light text-center p-3">
              Nenhum resultado encontrado.
            </div>
          ): (
            <div className="bg-light text-center p-3">
              Selecione uma escola para que os resultados sejam exibidos.
            </div>
          )) : (
            <div className="d-flex justify-content-center p-3 bg-light">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        {classes.length > 0 && pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end align-items-center">
            {pageCount > 1 && (
              <Pagination
                page={pageIndex}
                pageCount={pageCount}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                onClick={(page: number) => gotoPage(page)}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default Classes
