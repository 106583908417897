import React, { useContext, useEffect, useState } from 'react'
import AppContext from '@contexts/App'
import * as $School from '@services/School'
import * as $App from '@services/App'
import Select from 'react-select'
import { Spinner } from 'react-bootstrap'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

const App: React.FC<any> = () => {
  const { app, setApp, setApps, clients, isLoading, setIsLoading, setCanEdit } = useContext(AppContext)

  const [ schools, setSchools ] = useState<any[]>([])

  const SweetAlert = withReactContent(Swal)

  const onClose = () => {
    setCanEdit(true)
    setIsLoading(false)
    setSchools([])
    setApp(null)
  }

  return (
    <div className="col-12 col-md-3">
      <form>
        <div className="card">
          <div className="card-body">
            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label mb-0">Nome</label>
              <input type="text" id="name" name="name" className="form-control" defaultValue={app?.name} required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="appId" className="form-label mb-0">App ID</label>
              <input type="text" id="appId" className="form-control" disabled required />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="appSecret" className="form-label mb-0">App secret</label>
              <input type="text" id="appSecret" name="appSecret" className="form-control" />
            </div>

            <div className="form-group mb-3">
              <label>Cliente:</label>

              {clients.length ? (
                <Select
                  options={clients.map((client: any) => ({ value: client.id, label: client.name }))}
                  placeholder="Selecione um cliente"
                  isDisabled={!clients.length}
                  isSearchable
                  required
                />
              ) : null}
            </div>

            <div className="form-group mb-3">
              <label>Escola:</label>
              <Select
                options={schools.map((school: any) => ({ value: school.id, label: school.name }))}
                placeholder="Selecione uma escola"
                isDisabled={!schools.length}
                isSearchable
                isClearable
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="userId" className="form-label mb-0">Usuário ID</label>
              <input type="text" id="userId" name="userId" className="form-control" placeholder="Informe o ID de um usuário acadêmico" />
            </div>

            <div className="form-group">
              <label htmlFor="description" className="form-label mb-0">Descrição</label>
              <textarea name="description" className="form-control" rows={5} id="description"></textarea>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end">
            <button type="button" className="btn btn-outline-secondary pe-3 ps-3 me-2" onClick={onClose} disabled={isLoading}>Fechar</button>
            <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading}>
              <span>Atualizar</span> {isLoading && <Spinner animation="border" size="sm" variant="light" className="ms-2" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default App
