import React, { useContext, useState } from 'react'
import { usePagination, useSortBy, useTable } from 'react-table'
import Pagination from '@components/Pagination/Pagination'
import * as $User from '@services/User'
import IUser from '@interfaces/IUser'
import Select from '@components/Select/Select'
import Table from '@components/Table/Table'
import useUserColumns from '@hooks/useUserColumns'
import IClient from '@interfaces/IClient'
import AuthContext from '@contexts/Auth'
import { Spinner } from 'react-bootstrap'

const Users: React.FC<any> = () => {
  const { clients } = useContext(AuthContext)

  const [ users, setUsers ] = useState<IUser[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(false)

  const columns = useUserColumns()

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: users,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setIsLoading(true)

    const formData = new FormData(event.currentTarget)

    $User.all(formData).then(({ data }: any) => setUsers(data)).finally(() => setIsLoading(false))
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="row align-items-end">
          <div className="col-12 col-md-2">
            <div className="form-group mb-3">
              <label htmlFor="clientId">Cliente:</label>
              {clients && clients.length > 0 && (
                <Select
                  type="primary"
                  name="clientId"
                  options={clients.map((client: IClient) => ({
                    value: client.id,
                    label: client.name,
                  }))}
                  isSearchable
                  isClearable
                  placeholder="Selecione um cliente"
                />
              )}
            </div>
          </div>

          <div className="col-12 col-md-1">
            <div className="form-group mb-3">
              <label htmlFor="schoolId">Escola ID:</label>
              <input type="text" id="schoolId" name="schoolId" className="form-control" disabled={isLoading} />
            </div>
          </div>

          <div className="col-12 col-md-1">
            <div className="form-group mb-3">
              <label htmlFor="id">ID:</label>
              <input type="text" id="id" name="id" className="form-control" disabled={isLoading} />
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="form-group mb-3">
              <label htmlFor="name">Nome:</label>
              <input type="text" id="name" name="name" className="form-control" disabled={isLoading} />
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="form-group mb-3">
              <label htmlFor="email">E-mail:</label>
              <input type="text" id="email" name="email" className="form-control" disabled={isLoading} />
            </div>
          </div>

          <div className="col-12 col-md-2">
            <div className="form-group mb-3">
              <label htmlFor="userCode">RE:</label>
              <input type="text" id="userCode" name="userCode" className="form-control" disabled={isLoading} />
            </div>
          </div>

          <div className="col-12 col-md-1">
            <div className="form-group mb-3">
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                Pesquisar {isLoading && <Spinner animation="border" size="sm" />}
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="card">
        <div className="card-header">Usuários</div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <Table
              getTableProps={getTableProps}
              getTableHeadProps={getTableHeadProps}
              getTableBodyProps={getTableBodyProps}
              page={page}
              headerGroups={headerGroups}
              prepareRow={prepareRow}
              isLoading={isLoading}
              placeholder="Nenhum usuário encontrado com os filtros informados."
            />
          </div>
        </div>

        {pageCount > 1 && (
          <div className="card-footer d-flex justify-content-end">
            <Pagination
              page={pageIndex}
              pageCount={pageCount}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              onClick={(page: number) => gotoPage(page)}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default Users
