import React, { useContext, useMemo } from 'react'
import { CellProps, Column } from 'react-table'
import { WidgetsContext } from '@screens/Widgets/Widgets'
import IWidget from '@interfaces/IWidget'
import IRole from '@interfaces/IRole'

export type Client = IWidget['clients'][0] & {
  widgets: Array<Pick<IWidget, 'id' | 'name'> & { query: string | null, role: IRole | null }>
}

const useWidgetClientColumns = (onDelete: (id: number) => void) : Column<Client>[] => {
  const { setBuilder, setDashboard } = useContext(WidgetsContext)

  return useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id',
      style: {
        width: 60,
      },
    },
    {
      Header: 'Cliente',
      accessor: 'name',
    },
    {
      Header: 'Widgets',
      accessor: ({ widgets }) => (
        <div className="text-truncate">
          {widgets.map(({ id, name }) => (
            <span className="badge bg-secondary me-1" key={id}>
              {name}
            </span>
          ))}
        </div>
      ),
    },
    {
      Header: 'Ações',
      disableSortBy: false,
      Cell: ({ row: { original: client } }: React.PropsWithChildren<CellProps<Client, Client>>) => {
        return (
          <div className="d-flex justify-content-end">
            <button className="btn btn-sm ps-3 pe-3 btn-primary me-2" onClick={() => setBuilder({ client })}>
              Editar widgets
            </button>

            <button className="btn btn-sm ps-3 pe-3 btn-primary me-2" onClick={() => setDashboard({ client })}>
              Editar dashboard
            </button>

            <button type="button" className="btn btn-sm ps-3 pe-3 btn-danger" onClick={() => onDelete(client.id)}>
              Excluir
            </button>
          </div>
        )
      },
      style: {
        width: 160,
        textAlign: 'right',
      },
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ], [])
}

export default useWidgetClientColumns
