import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { WidgetsContext } from '../Widgets'
import { Modal } from './Dashboard.styles'
import { Spinner } from 'react-bootstrap'
import $App from '@services/App'

const Dashboard: React.FC = () => {
  const { dashboard, setDashboard } = useContext(WidgetsContext)

  const [ token, setToken ] = useState<string | null>(null)

  const $login = useMutation({
    mutationFn: $App.dashboard.login,
    onSuccess: ({ data: { token } }) => {
      setToken(token)
    },
  })

  useEffect(() => {
    if (!$login.isIdle || !dashboard || token)
      return

    $login.mutate(null)
  }, [$login, dashboard, token])

  return (
    <Modal show={!!dashboard} onHide={() => setDashboard(null)} size="xl" centered>
      <Modal.Body className="p-1">
        {token && token.length > 0 ? (
          <iframe
            src={`${process.env.REACT_APP_API_DASHBOARD_URL}/admin?token=${token}&clientId=${dashboard?.client?.id ?? ''}`}
            className="dashboard-iframe"
          />
        ) : (
          <div className="loading">
            <Spinner animation="border" />
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default Dashboard
