import React, { createContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import IApp from '@interfaces/IApp'
import IClient from '@interfaces/IClient'

type AppContextProps = {
  app: IApp | null
  setApp: React.Dispatch<React.SetStateAction<IApp | null>>
  apps: IApp[]
  setApps: React.Dispatch<React.SetStateAction<IApp[]>>
  clients: IClient[]
  setClients: React.Dispatch<React.SetStateAction<IClient[]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  canEdit: boolean
  setCanEdit: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (app: any) => void
}

const AppContext = createContext<AppContextProps>({ } as AppContextProps)

export const AppProvider: React.FC<any> = () => {
  const [ app, setApp ] = useState<IApp | null>(null)
  const [ apps, setApps ] = useState<IApp[]>([])
  const [ clients, setClients ] = useState<IClient[]>([])
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ canEdit, setCanEdit ] = useState<boolean>(true)

  const onClick = (app?: IApp | null) => {
    setCanEdit(false)
  }

  return (
    <AppContext.Provider
      value={{
        app,
        setApp,
        apps,
        setApps,
        clients,
        setClients,
        isLoading,
        setIsLoading,
        canEdit,
        setCanEdit,
        onClick,
      }}
    >
      <Outlet />
    </AppContext.Provider>
  )
}

export default AppContext
