import React, { useRef, useState } from 'react'
import IUserGroup from '@interfaces/IUserGroup'
import Select from 'react-select/async'
import IUser from '@interfaces/IUser'
import axios, { CancelTokenSource } from 'axios'
import $User from '@services/User'
import $UserGroup from '@services/UserGroup'

type Props = {
  user: IUser
  userGroup: IUserGroup | null
  onLoad: (userGroup: IUserGroup) => void
}

const Add: React.FC<Props> = ({ user, userGroup, onLoad }: Props) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ isUserSelected, setIsUserSelected ] = useState<boolean>(false)
  const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false)

  const selectRef = useRef<Select<{ value: string, label: string }> | null>(null)
  const cancelTokenSourceRef = useRef<CancelTokenSource | null>(null)

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formData = new FormData(event.currentTarget)

    setIsLoading(true)
    setIsMenuOpen(false)

    const userCode = Number(formData.get('userCode'))

    try {
      let response

      if (!userGroup) {
        response = await $UserGroup.store(user.id, userCode)
      } else {
        response = await $UserGroup.update(userGroup.id, userCode)
      }

      onLoad(response.data)
    } finally {
      setIsLoading(false)
    }
  }

  const loadOptions = (value: string, callback: (options: { value: string, label: string }[]) => void) => {
    if (value.length >= 7) {
      const formData = new FormData()

      formData.set('search', value)

      try {
        if (cancelTokenSourceRef.current)
          cancelTokenSourceRef.current.cancel('Canceling previous request')
      } catch {
        //
      }

      cancelTokenSourceRef.current = axios.CancelToken.source()

      $User.all(formData, {
        cancelToken: cancelTokenSourceRef.current.token,
      }).then(({ data: users }) => {
        callback(users.map((user: IUser) => ({
          value: user.userCode,
          label: `${user.userCode}: ${user.name}` + (user.email.length > 0 ? ` (${user.email})` : ''),
        })))
      })
    } else {
      callback([])
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-md-6 offset-md-6 d-flex justify-content-end align-items-center gap-3">
          <div className="react-async-select flex-fill">
            <Select
              ref={selectRef}
              name="userCode"
              placeholder="Selecione um usuário"
              loadOptions={loadOptions}
              closeMenuOnSelect={false}
              menuPlacement="top"
              noOptionsMessage={() => 'Digite um RE ou e-mail de usuário para pesquisar'}
              loadingMessage={() => 'Carregando...'}
              onChange={(value) => setIsUserSelected(!!value)}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuClose={() => setIsMenuOpen(false)}
              onBlurResetsInput={false}
              onCloseResetsInput={false}
              menuIsOpen={isMenuOpen}
              isDisabled={isLoading}
              isMulti={false}
              isClearable
              required
            />
          </div>

          <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={isLoading || !isUserSelected}>
            Adicionar
          </button>
        </div>
      </div>
    </form>
  )
}

export default Add
