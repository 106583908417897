import React, { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as $StudyPlan from '@services/StudyPlan'
import { Buttons } from './StudyPlan.styles'
import { Spinner } from 'react-bootstrap'
import { ReactComponent as Sort } from '@resources/svg/sort.svg'
import columns from '@data/ReactTableStudyPlansColumns'
import Pagination from '@components/Pagination/Pagination'
import { usePagination, useSortBy, useTable } from 'react-table'
import Study from './Study/Study'
import IStudyPlan from '@interfaces/IStudyPlan'
import * as Utils from '@helpers/Utils'
import { getCssProperty } from '@helpers/Theme'

const StudyPlan: React.FC<any> = () => {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ isStudy, setIsStudy ] = useState<boolean>(false)
  const [ studyPlans, setStudyPlans ] = useState<IStudyPlan[]>([])
  const [ studyPlan, setStudyPlan ] = useState<IStudyPlan|null>(null)
  const [ search, setSearch ] = useState<string>('')

  const SweetAlert = withReactContent(Swal)

  const getStudyPlans = () => {
    setStudyPlans([])
    setIsLoading(true)
    $StudyPlan.all().then(({ data }: any) => setStudyPlans(data)).finally(() => setIsLoading(false))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getStudyPlans, [])

  const data = useMemo(() => studyPlans.filter((studyPlan: IStudyPlan) => {
    let exists = true

    if (search.length > 0)
      exists = studyPlan.id.toString().includes(search) ||
      studyPlan.name.toLowerCase().includes(search.toLowerCase())

    return exists
  }), [studyPlans, search])

  const {
    getTableProps,
    getTableHeadProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {
      pageIndex,
    },
  }: any = useTable({
    columns,
    data: data,
    initialState: {
      pageIndex: 0,
      pageSize: 10,
    } as any,
  }, useSortBy, usePagination)

  const studyNew = () => {
    setStudyPlan({
      id: -Utils.random(),
    } as IStudyPlan)
    setIsStudy(true)
  }

  const cancel = () => {
    setStudyPlan({
      id: -Utils.random(),
    } as IStudyPlan)
    setIsStudy(false)
  }

  const onSuccess = () => {
    getStudyPlans()
    setIsStudy(false)
  }

  const onUpdate = (study: any) => {
    setStudyPlan(study)
    setIsStudy(true)
  }

  const onDelete = (studyPlanId: number) => {
    SweetAlert.fire({
      title: 'Deseja prosseguir?',
      text: 'Deseja prosseguir com a exclusão? Você não poderá voltar atrás dessa decisão.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: getCssProperty('--secondary-color'),
      cancelButtonColor: getCssProperty('--primary-color'),
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result: any) => {
      if (result.value && studyPlanId > 0) {
        setIsLoading(true)

        SweetAlert.showLoading()

        $StudyPlan.destroy(studyPlanId as number).then(() => {
          setStudyPlans(studyPlans => studyPlans.filter(studyPlan => studyPlan.id !== studyPlanId))
        }).finally(() => {
          setIsLoading(false)
          SweetAlert.fire({
            title: 'Sucesso!',
            text: 'Plano de estudo excluído com sucesso.',
            icon: 'success',
            didOpen: () => SweetAlert.hideLoading(),
          })
        })
      }
    })
  }

  return (
    <>
      {!isStudy ? (
        <>
          <div className="row align-items-end mb-3">
            <div className="col-12 col-lg-10">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="form-group">
                    <label htmlFor="search">Pesquisar:</label>
                    <input type="text" id="search" name="search" className="form-control"
                      placeholder="Pesquise pelo ID, prova"
                      onChange={e => setSearch(e.target.value)}
                      disabled={!studyPlans.length || isLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Buttons className="col-12 col-lg-2 d-flex justify-content-end">
              <button className="btn btn-primary" onClick={studyNew} disabled={isLoading}>Cadastrar</button>
            </Buttons>
          </div>
          <div className="card">
            <div className="card-header">
              Plano de Estudos
            </div>
            <div className="card-body p-0">
              {!isLoading ? (data.length ? (
                <div className="table-responsive">
                  <table {...getTableProps()} className="table table-default mb-0">
                    <thead>
                      {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                          {headerGroup.headers.map((column: any, columnIndex: number) => (
                            <th {...column.getHeaderProps(column.getSortByToggleProps())} className="sortBy" key={columnIndex}>
                              {column.render('Header')}
                              <Sort className={'sort-icon ms-1 ' + (column.isSorted ? column.isSortedDesc ? 'sorted-up' : 'sorted-down' : 'unsorted')} />
                            </th>
                          ))}
                          <th style={{ width: 100 }}>Link</th>
                          <th style={{ width: 100 }}>Ações</th>
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page.map((row: any, rowIndex: number) => {
                        prepareRow(row)
                        return (
                          <tr {...row.getRowProps()} key={rowIndex}>
                            {row.cells.map((cell: any, index: number) => {
                              return (
                                <td style={{ verticalAlign: 'middle' }} {...cell.getCellProps()} key={index}>
                                  <span className="text-truncate">{cell.render('Cell')}</span>
                                </td>
                              )
                            })}
                            <td style={{ verticalAlign: 'middle' }}>
                              <Buttons className="d-flex justify-content-center">
                                <a target="_blank" className="btn btn-sm btn-outline-primary mb-3" href={row.original.link} rel="noreferrer">Visualizar</a>
                              </Buttons>
                            </td>
                            <td style={{ verticalAlign: 'middle' }}>
                              <Buttons className="d-flex justify-content-center">
                                <button className="btn btn-sm btn-primary mb-3 me-1" onClick={() => onUpdate(row.original)} disabled={isLoading}>Editar</button>
                                <button className="btn btn-sm btn-danger mb-3" onClick={() => onDelete(row.original.id)} disabled={isLoading}>Excluir</button>
                              </Buttons>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="bg-light text-center p-3">
                  Nenhum resultado encontrado.
                </div>
              )) : (
                <div className="d-flex justify-content-center p-3 bg-light">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
            {data.length > 0 && pageCount > 1 && (
              <div className="card-footer d-flex justify-content-end align-items-center">
                <Pagination
                  page={pageIndex}
                  pageCount={pageCount}
                  canPreviousPage={canPreviousPage}
                  canNextPage={canNextPage}
                  nextPage={nextPage}
                  previousPage={previousPage}
                  onClick={(page: number) => gotoPage(page)}
                />
              </div>
            )}
          </div>
        </>
      ) :
        <Study
          onCancel={cancel}
          studyPlan={studyPlan}
          setStudyPlan={setStudyPlan}
          onSuccess={onSuccess}
        />}
    </>
  )
}

export default StudyPlan
