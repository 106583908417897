import React from 'react'
import axios from 'axios'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from '@contexts/Auth'
import { QueryClient, QueryClientProvider } from 'react-query'
import IClient from '@interfaces/IClient'
import Main from './routes/Main'
import './i18n'

import 'flexmonster/flexmonster.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './resources/scss/app.scss'

const token: string = localStorage.getItem('token') || ''
const client: IClient = JSON.parse(localStorage.getItem('client') || '{}')

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

if (client?.id) {
  axios.defaults.headers.common['ClientId'] = client.id
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Main />
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
