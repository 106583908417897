import axios from 'axios'
import React, { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import IPlatform from '@interfaces/IPlatform'
import IPlatformAssociation from '@interfaces/PlatformAssociation/IPlatformAssociation'
import IDegreeGroup from '@interfaces/IDegreeGroup'
import * as $Platform from '@services/Platform'
import * as $Student from '@services/Users/Student'
import IUser from '@interfaces/IUser'
import IPlatformAssociationProduct from '@interfaces/PlatformAssociation/IPlatformAssociationProduct'

interface IStudentAndProductsContextProps {
  student: IUser
  platforms: IPlatform[]
  setPlatforms: React.Dispatch<React.SetStateAction<IPlatform[]>>
  selectedAssociation: IPlatformAssociationProduct[]
  setSelectedAssociation: React.Dispatch<React.SetStateAction<IPlatformAssociationProduct[]>>
  selectedDisassociation: IPlatformAssociationProduct[]
  setSelectedDisassociation: React.Dispatch<React.SetStateAction<IPlatformAssociationProduct[]>>
  associations: IPlatformAssociation[]
  setAssociations: React.Dispatch<React.SetStateAction<IPlatformAssociation[]>>
  degreeGroups: IDegreeGroup[][]
  setDegreeGroups: React.Dispatch<React.SetStateAction<IDegreeGroup[][]>>
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  refresh: () => void
}

const StudentAndProductsContext = createContext<IStudentAndProductsContextProps>({ } as IStudentAndProductsContextProps)

export const StudentAndProductsProvider: React.FC<PropsWithChildren<{ student: IUser, year: number }>> = ({ student, year, children }) => {
  const [ isLoading, setIsLoading ] = useState<boolean>(true)
  const [ platforms, setPlatforms ] = useState<IPlatform[]>([])
  const [ associations, setAssociations ] = useState<IPlatformAssociation[]>([])
  const [ degreeGroups, setDegreeGroups ] = useState<IDegreeGroup[][]>([])
  const [ selectedAssociation, setSelectedAssociation ] = useState<IPlatformAssociationProduct[]>([])
  const [ selectedDisassociation, setSelectedDisassociation ] = useState<IPlatformAssociationProduct[]>([])

  useEffect(() => {
    if (student.schoolId && student.degreeId && student.classId) {
      axios.all([
        $Platform.allFromSchoolId(student.schoolId, {
          degreeId: student.degreeId,
        }),
        $Student.platformAssociations(student.schoolId, student.id, {
          classId: student.classId,
        }),
        $Platform.degreeGroups(student.schoolId, student.degreeId, year),
      ]).then(axios.spread(({ data: platforms }: any, { data: associations }: any, { data: degreeGroups }: any) => {
        setPlatforms(platforms)
        setAssociations(associations)
        setDegreeGroups(degreeGroups)
      })).finally(() => setIsLoading(false))
    }
  }, [setAssociations, setDegreeGroups, setIsLoading, setPlatforms, student, year])

  const refresh = useCallback(() => {
    setIsLoading(true)
    setSelectedAssociation([])
    setSelectedDisassociation([])

    axios.all([
      $Platform.degreeGroups(Number(student.schoolId), Number(student.degreeId), year),
      $Student.platformAssociations(Number(student.schoolId), student.id, {
        classId: student.classId,
      }),
    ]).then(axios.spread(({ data: degreeGroups }: any, { data: associations }: any) => {
      setDegreeGroups(degreeGroups)
      setAssociations(associations)
    })).finally(() => setIsLoading(false))
  }, [student.classId, student.degreeId, student.id, student.schoolId, year])

  return (
    <StudentAndProductsContext.Provider
      value={{
        student,
        platforms,
        setPlatforms,
        selectedAssociation,
        setSelectedAssociation,
        selectedDisassociation,
        setSelectedDisassociation,
        associations,
        setAssociations,
        degreeGroups,
        setDegreeGroups,
        isLoading,
        setIsLoading,
        refresh,
      }}
    >
      {children}
    </StudentAndProductsContext.Provider>
  )
}

export default StudentAndProductsContext
