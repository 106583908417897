import React, { useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { Spinner } from 'react-bootstrap'
import * as $Client from '@services/Client'
import $PermissionProfile from '@services/PermissionProfile'
import IPermissionProfile from '@interfaces/IPermissionProfile'
import { Thumbnail } from './Client.styles'

const Resource: React.FC<{ name: string, label: string, defaultValue: string | null }> = ({ name, label, defaultValue }) => {
  const [value, setValue] = React.useState<string | null>(defaultValue)
  const [file, setFile] = React.useState<File | null>(null)

  const fileRef = React.useRef<HTMLInputElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length)
      return

    setFile(e.target.files[0])

    if (inputRef.current) {
      inputRef.current.value = ''
      setValue(null)
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)

    if (fileRef.current) {
      fileRef.current.value = ''
      setFile(null)
    }
  }

  const style = useMemo(() => {
    return {
      backgroundImage: `url(${file ? URL.createObjectURL(file) : value ?? ''})`,
    }
  }, [file, value])

  return (
    <Thumbnail className="row align-items-center">
      <div className="row align-items-center">
        <div className="col col-4">
          <label>
            <span style={style} />
            <input ref={fileRef} type="file" name={`${name}.file`} accept="image/*" onChange={onFileChange} />
          </label>
        </div>

        <div className="col col-8">
          <div className="form-group">
            <label htmlFor={`#${name}`}>{label}:</label>
            <input ref={inputRef} type="text" name={`${name}.url`} className="form-control mb-2" defaultValue={defaultValue ?? ''} onChange={onInputChange} />
          </div>
        </div>
      </div>
    </Thumbnail>
  )
}

const Client: React.FC<any> = () => {
  const { id } = useParams()

  const $client = useQuery({
    queryFn: $Client.find,
    queryKey: [
      'client', Number(id),
    ],
  })

  const $permissions = useQuery({
    queryFn: $PermissionProfile.all,
    queryKey: [
      'permissions',
    ],
  })

  const $update = useMutation($Client.update)

  const { data: client } = $client

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!id)
      return

    $update.mutate({
      id: Number(id),
      formData: new FormData(e.currentTarget),
    })
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex justify-content-end mb-3">
        <Link to="/clientes" className="btn btn-outline-primary">Voltar</Link>
      </div>

      <div className="card">
        <div className="card-header">
          Editar cliente ({client?.name})
        </div>

        <div className="card-body pb-0">
          {client ? (
            <div className="row">
              <div className="col-12 mb-3">
                <div className="form-group">
                  <label htmlFor="name">Nome:</label>
                  <input type="text" name="name" className="form-control" id="name" defaultValue={client.name} />
                </div>
              </div>

              <div className="col-12 col-md-4 mb-3">
                <div className="form-group">
                  <label htmlFor="permissionProfileId">Permissão:</label>
                  <select name="permissionProfileId" className="form-control" defaultValue={client.permissionProfileId}>
                    {$permissions.data?.data.map(({ id, name }: IPermissionProfile) => {
                      return <option key={id} value={id}>{name}</option>
                    })}
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-4 mb-3">
                <div className="form-group">
                  <label htmlFor="canEdit">Editável:</label>
                  <select name="canEdit" className="form-control" defaultValue={client.canEdit ? 'true' : 'false'}>
                    <option value="false">Não</option>
                    <option value="true">Sim</option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-4 mb-3">
                <div className="form-group">
                  <label htmlFor="active">Status:</label>
                  <select name="active" className="form-control" defaultValue={client.active ? 'true' : 'false'}>
                    <option value="true">Ativo</option>
                    <option value="false">Inativo</option>
                  </select>
                </div>
              </div>

              <div className="col-12 col-md-4 mb-3">
                <Resource name="imageHeader" label="Logotipo padrão" defaultValue={client.imageHeader} />
              </div>

              <div className="col-12 col-md-4 mb-3">
                <Resource name="logoImageMin" label="Logotipo compacto" defaultValue={client.logoImageMin} />
              </div>

              <div className="col-12 col-md-4 mb-3">
                <Resource name="favicon" label="Favicon" defaultValue={client.favicon} />
              </div>

              <div className="col-12 col-md-3 mb-3">
                <div className="form-group">
                  <label htmlFor="primaryColor">Cor primária:</label>
                  <input type="color" name="primaryColor" className="form-control" defaultValue={client.primaryColor} />
                </div>
              </div>

              <div className="col-12 col-md-3 mb-3">
                <div className="form-group">
                  <label htmlFor="secondaryColor">Cor secundária:</label>
                  <input type="color" name="secondaryColor" className="form-control" defaultValue={client.secondaryColor} />
                </div>
              </div>

              <div className="col-12 col-md-3 mb-3">
                <div className="form-group">
                  <label htmlFor="tertiaryColor">Cor terciária:</label>
                  <input type="color" name="tertiaryColor" className="form-control" defaultValue={`${client.tertiaryColor ?? '#000000'}`} />
                </div>
              </div>

              <div className="col-12 col-md-3 mb-3">
                <div className="form-group">
                  <label htmlFor="quaternaryColor">Cor quaternária:</label>
                  <input type="color" name="quaternaryColor" className="form-control" defaultValue={`${client.quaternaryColor ?? '#000000'}`} />
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center p-3">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>

        <div className="card-footer d-flex justify-content-end">
          <button type="submit" className="btn btn-primary pe-3 ps-3" disabled={$update.isLoading || $client.isLoading}>
            Atualizar {($update.isLoading || $client.isLoading) && <Spinner animation="border" size="sm" className="ms-2" />}
          </button>
        </div>
      </div>
    </form>
  )
}

export default Client
