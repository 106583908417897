/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import IUserGroup from '@interfaces/IUserGroup'
import axios, { AxiosResponse } from 'axios'

const all = () : Promise<AxiosResponse<IUserGroup[]>> => {
  return axios.get('user-groups')
}

const find = (id: number) : Promise<AxiosResponse<IUserGroup>> => {
  return axios.get(`user-groups/${id}`)
}

const store = (userId: number, userCode: number) : Promise<AxiosResponse<IUserGroup>> => {
  return axios.post('user-groups', {
    userId,
    userCode,
  })
}

const update = (id: number, userCode: number) : Promise<AxiosResponse<IUserGroup>> => {
  return axios.put(`user-groups/${id}`, {
    userCode,
  })
}

const remove = (id: number, userCode: number) : Promise<AxiosResponse<IUserGroup>> => {
  return axios.delete(`user-groups/${id}`, {
    data: {
      userCode,
    },
  })
}

const $UserGroup = {
  all,
  find,
  store,
  update,
  remove,
}

export default $UserGroup
